import React, { useState, useEffect } from 'react';
import EquipoDobles from './components/PlayerTeams';
import PlayerPairing from './components/PlayerPairing';
import { Player } from './interfaces/Player';
import { fetchPlayers } from './services/api';
import TeamManagement from './components/TeamManagement'; // Asegúrate de la ruta correcta


const App: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);

  useEffect(() => {
    fetchPlayers().then((data) => setPlayers(data));
  }, []);

  const togglePlayerSelection = (player: Player) => {
    if (selectedPlayers.includes(player)) {
      setSelectedPlayers(selectedPlayers.filter(p => p.id !== player.id));
    } else {
      if (selectedPlayers.length < 6) {
        setSelectedPlayers([...selectedPlayers, player]);
      } else {
        console.log("You can only select up to 6 players.");
      }
    }
  };

  return (
    <div>
      {/* <h1>El Trebol - Equipo de dobles</h1> */}
      {/* <PlayerList
        players={players}
        selectedPlayers={selectedPlayers}
        onTogglePlayer={togglePlayerSelection}
      /> */}
     {/*  <EquipoDobles/> */}
     {/* <EquipoDobles setPlayers={setPlayers} /> */}
    

      <TeamManagement  /> {/* Asegúrate de que TeamManagement se esté usando */}
    
      {/* <PlayerPairing players={players} /> */}
    </div>
  );
};

export default App;
