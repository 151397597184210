import React, { useState } from 'react';
import { Player } from '../interfaces/Player'; // Asegúrate de que la ruta sea correcta
import  PlayerPairing  from './PlayerPairing';
import PlayerList from './PlayerList';


interface EquipoDoblesProps {
  players: Player[]; // Recibe players desde App
  setPlayers: React.Dispatch<React.SetStateAction<Player[]>>;
}



const EquipoDobles: React.FC<EquipoDoblesProps> = ({ players, setPlayers }) => {
  const [titulo, setTitulo] = useState('El Trebol - Equipo de dobles');

  

  // Función para hacer la consulta a la API
  const fetchPlayers = (sexo: string) => {
    // Primero limpiamos la lista de jugadores
    setPlayers([]);

    fetch(`/api/api.php?sexo=${sexo}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => setPlayers(data))
      .catch((error) => console.error('Error al obtener los jugadores:', error));

     

  }

  // Función que se ejecuta al hacer clic en Equipo Masculino
  const handleMasculino = () => {
    setTitulo('El Trebol - Equipo de dobles Masculino');
    fetchPlayers('M'); // Consulta jugadores masculinos
  };

  // Función que se ejecuta al hacer clic en Equipo Femenino
  const handleFemenino = () => {
    setTitulo('El Trebol - Equipo de dobles Femenino');
    fetchPlayers('F'); // Consulta jugadores femeninos
  };

  return (
    <div>
      <h1>{titulo}</h1>

  
      <center>
        <button className='button-masc' onClick={handleMasculino}>
          Equipo Masculino
        </button>
        <button className='button-fem' onClick={handleFemenino}>
          Equipo Femenino
        </button>
      </center>

       {/* Aquí pasas los jugadores a PlayerPairing */}
       <PlayerPairing players={players} />
    </div>
  );
};

export default EquipoDobles;
