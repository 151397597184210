import React, { useState , useEffect  } from 'react';
import EquipoDobles from './PlayerTeams';
import PlayerList from './PlayerList';
import { Player } from '../interfaces/Player';
/* import tennisBallIcon from '../../public/tenis_images-remove.png'; */
import tennisBallIcon from './tenis_images-remove.png';
import PlayerPairing from './PlayerPairing';

/* interface Props {
    players: Player[];
    setPlayers: React.Dispatch<React.SetStateAction<Player[]>>;
  } */


const TeamManagement = () => {
    console.log('TeamManagement component mounted');

  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);

/* Solo para pruebas inicio */
/*   const handleFetchPlayers = () => { */
    // Simulación de datos estáticos
/*     const fetchedPlayers: Player[] = [
        { id: 1, nombre_apellido: 'Jugador 1' , sexo:'M',numero_jugador: 10 },
        { id: 2, nombre_apellido: 'Jugador 2', sexo: 'F',numero_jugador: 12 }, */
      // Otros jugadores...
/*     ];
    setPlayers(fetchedPlayers); */
  /* };

  useEffect(() => {
    handleFetchPlayers(); 
  }, []);  */

  /* Solo para pruebas FINNNNN */

  const handleTogglePlayer = (player: Player) => {   
    console.log('Toggled player:', player);
    setSelectedPlayers((prevSelected) => {
      if (prevSelected.includes(player)) {
        return prevSelected.filter((p) => p.id !== player.id);
      }
      return [...prevSelected, player];
    });




  };

  const getPlayerColor = (playerId: number): string => {
    // Define tu lógica de colores aquí
    return '#ffeb3b'; // Ejemplo de color
  };
  

 // Solo se ejecuta cuando `players` cambia
/*   useEffect(() => {
    console.log('Jugadores actuales del userEffect:', players);
  }, [players]);  */


  return (
    <div>
      <EquipoDobles setPlayers={setPlayers} players={players} />

      
     {/*  <PlayerList
        players={players}
        selectedPlayers={selectedPlayers}
        onTogglePlayer={handleTogglePlayer}
        getPlayerColor={getPlayerColor}
        tennisBallIcon={tennisBallIcon}
      />
 */}



        {/* <PlayerList players={players} /> */}

    </div>
  );
};

export default TeamManagement;
