import axios from 'axios';
import { Player } from '../interfaces/Player';

/* const API_URL = 'http://localhost/06-AppEquiposTenis_ConAPI/api/api.php'; */ // Ajusta la URL según tu configuración
const API_URL = '/api/api.php';

export const fetchPlayers = async (): Promise<Player[]> => {
  try {
    const response = await axios.get<Player[]>(API_URL);
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching players :', error);
    return [];
  }
};
