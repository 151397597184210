import React, { useState } from 'react';
import { Player } from '../interfaces/Player';
import PlayerList from './PlayerList';
import '../styles/PlayerPairing.css';

const tennisBallIcon = '/tenis_images-remove.png'; // Asegúrate de tener el link a la imagen

interface PlayerPairingProps {
  players: Player[]; // Define que el componente acepta un array de jugadores como prop
}

const PlayerPairing: React.FC<PlayerPairingProps> = ({ players }) => {
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);

  const togglePlayerSelection = (player: Player) => {
    if (selectedPlayers.includes(player)) {
      setSelectedPlayers(selectedPlayers.filter(p => p.id !== player.id));
    } else {
      if (selectedPlayers.length < 6) {
        setSelectedPlayers([...selectedPlayers, player]);
      } else {
        console.log("You can only select up to 6 players.");
      }
    }
  };

  interface PlayerPair {
    pair: [Player, Player];
    sum: number;
  }

  const getPlayerRank = (playerId: number): number => {
    const sortedSelectedPlayers = [...selectedPlayers].sort(
      (a, b) => a.numero_jugador - b.numero_jugador
    );
    const rank = sortedSelectedPlayers.findIndex(p => p.id === playerId) + 1;
    return rank > 0 ? rank : 0;
  };

  const getPlayerPairs = (): PlayerPair[] => {
    if (selectedPlayers.length < 2) return [];

    const playersWithRank = selectedPlayers.map(player => ({
      ...player,
      selectedRank: getPlayerRank(player.id)
    }));

    const pairs: PlayerPair[] = [];
    for (let i = 0; i < playersWithRank.length - 1; i += 2) {
      if (i + 1 < playersWithRank.length) {
        const pair: [Player, Player] = [
          playersWithRank[i],
          playersWithRank[i + 1],
        ];
        const sum = (pair[0].selectedRank || 0) + (pair[1].selectedRank || 0);
        pairs.push({ pair, sum });
      }
    }

    pairs.sort((a, b) => a.sum - b.sum);

    return pairs;
  };

  const handleRemovePlayer = (player: Player) => {
    setSelectedPlayers(selectedPlayers.filter(p => p.id !== player.id));
  };

  const handleResetSelection = () => {
    setSelectedPlayers([]); // Elimina todos los jugadores seleccionados
  };

  const pairs = getPlayerPairs();

  const getPlayerColor = (playerId: number) => {
    const teamIndex = pairs.findIndex(pairData =>
      pairData.pair.some(player => player.id === playerId)
    );
    const colors = ['#1d8b0e', '#d88d1e', '#1f7ce7'];

    return colors[teamIndex % colors.length];
  };

  return (
    <div className="player-pairing-container">
     {/*  <h2>Lista de jugadores</h2> */}
      <PlayerList
        players={players}
        selectedPlayers={selectedPlayers}
        onTogglePlayer={togglePlayerSelection}
        getPlayerColor={getPlayerColor}
        tennisBallIcon={tennisBallIcon} // Pasamos la imagen como prop
      />

      <button onClick={handleResetSelection} className="reset-button">Reiniciar</button>

      <div className="teams-container">
        {pairs.map((pairData, index) => (
          <div className="team-card" key={index}>
            <h3>Dobles {index + 1} - <span>Suma de orden: {pairData.sum}</span></h3>
            <div>
              {pairData.pair.map((player) => (
                <div
                  key={player.id}
                  className="team-player"
                  style={{
                    backgroundColor: getPlayerColor(player.id),
                    padding: '5px',
                    borderRadius: '4px',
                    margin: '2px 0',
                  }}
                >
                  {player.numero_jugador} - {player.nombre_apellido} (Orden: {player.selectedRank})
                  {selectedPlayers[selectedPlayers.length - 1]?.id === player.id && (
                    <img 
                      src={tennisBallIcon}
                      alt="Tennis Ball"
                      style={{ width: '20px', height: '20px', marginLeft: '10px', marginTop: '5px', marginBottom: '0px' }}
                    />
                  )}
                  <button onClick={() => handleRemovePlayer(player)} style={{ marginLeft: '10px' }}>❌</button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerPairing;
