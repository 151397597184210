import React from 'react';
import { Player } from '../interfaces/Player';

interface PlayerListProps {
  players: Player[];
  selectedPlayers: Player[];
  onTogglePlayer: (player: Player) => void;
  getPlayerColor: (playerId: number) => string;
  tennisBallIcon: string;
}

const PlayerList: React.FC<PlayerListProps> = ({
  players,
  selectedPlayers,
  onTogglePlayer,
  getPlayerColor,
  tennisBallIcon
}) => {

    // Log para verificar los jugadores recibidos
    /* console.log('Jugadores recibidos en PlayerList:', players); */

  const lastSelectedPlayer = selectedPlayers[selectedPlayers.length - 1];

  // Ordenar jugadores seleccionados por numero_jugador
  const sortedSelectedPlayers = [...selectedPlayers].sort(
    (a, b) => a.numero_jugador - b.numero_jugador
  );

  return (
    <ul>
      {players.map((player) => {
        const isSelected = selectedPlayers.includes(player);

        // Asignar el número según el lugar en la lista ordenada
        const selectedRank =
          sortedSelectedPlayers.findIndex((p) => p.id === player.id) + 1;

        return (
          <li
            key={player.id}
            className={isSelected ? 'selected' : ''}
            style={{
              backgroundColor: isSelected
                ? getPlayerColor(player.id)
                : '#d9eb75', // Asignar el color según el equipo
              padding: '5px',
              borderRadius: '4px',
              margin: '2px 0',
              cursor: 'pointer',
            }}
            onClick={() => onTogglePlayer(player)}
          >
            
            {player.numero_jugador} - {player.nombre_apellido}  {/* Mostrar el número asignado si el jugador está seleccionado */}
            {isSelected && selectedRank > 0 && selectedRank <= 6 && (
              <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                ( {selectedRank} )
              </span>
            )}
            {lastSelectedPlayer?.id === player.id && (
              <img
                src={tennisBallIcon}
                alt="Tennis Ball"
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '10px',
                  marginTop: '5px',
                  marginBottom: '0px',
                }}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default PlayerList;
